main{
    padding: 50px 100px 50px 100px;
}

#basic-nav-dropdown {
    margin-right: 50px;
  }

  .ml-10{
    margin-left: 10px;
  }

  .form-container {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;
}

.footer {
  background-color: #f8f9fa; /* Light grey background */
  color: #343a40; /* Dark grey text */
  font-size: 14px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
}

.logo{
  width: auto;
    height: 36px;

}